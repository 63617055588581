
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {loadPaginatedEstore, send_instore, viewExpiryDate}  from "@/api/estore";
import { string } from "yup/lib/locale";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import BalanceDetails from "@/components/cards/BalanceDetails.vue";
import EstoreTabNavigator from "@/components/cards/EstoreTabNavigator.vue";
import EstoreExpiry from "@/components/estore/EstoreExpiry.vue";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from  "@/views/ECShopAdvisory.vue";


export default defineComponent({
  name: "reorder",
  components: {
    // BalanceDetails,
    EstoreTabNavigator,
    EstoreExpiry,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  data() {
    return { 
      list : [],
      pagination: {
        total: 0,
        per_page: 10,    // required
        current_page: 1, // required
        last_page: 0,    // required
        from: 1,
        to: 12
      },
      isBCOAccount : false,
      current_usid : "",
      store_count : {
        type: string,
        default : []
      },
			tousid : {
        type: string,
        default : []
      },
			pos : {
        type: string,
        default : []
      },
      isprocessing : {
        type: string,
        default : []
      },
			retro : "0",
      loading: true,
      expiry_list : [],
      isOverriden : false,
      modal_prodname : "",
    }
  },
  mounted(){
      this.loadPaginatedEstore();
      if(localStorage.getItem("override_cifid") != null){
        this.isOverriden = true;
      }
  },
  methods : {
    async viewExpiryDate(pcode,pname){
      const response = await viewExpiryDate(pcode);
      this.expiry_list = response.data.estoreExpiryDatePageList;
      this.modal_prodname = pname;
    },
    async setDefaultValues(listSize:number){
      let ctr = 0;
      for(ctr = 0; ctr < listSize; ctr++){
          this.store_count[ctr] = "0";
          this.pos[ctr] = "L";
          this.tousid[ctr] = this.current_usid;
          this.isprocessing[ctr] = "0";
      }
    },

    async loadPaginatedEstore(){ 
      this.loading = true;
      const options = {
        params: {
          page: 1,
          /* additional parameters */
        }
      };
      const response = await loadPaginatedEstore();
      this.list = response.data.pageList;
      this.pagination = response.data.pagination;
      this.isBCOAccount = response.data.isBCOAccount;
      localStorage.setItem("isBCOAccount", response.data.isBCOAccount);
      this.current_usid = String(localStorage.getItem("ec_current_usid"));
      let listSize = this.list.length;
      this.setDefaultValues(listSize);
      this.loading = false;

    },
    async send_instore(prod:string, index:number){
      this.loading = true;
      const store_count = this.store_count[index];
      const tousid = this.tousid[index];
      const pos = this.pos[index];
      const retro = this.retro;

      this.isprocessing[index] = "1";

      var param = {
        "store" : store_count,
        "prod" : prod,
				"usid" : tousid,
				"pos" : pos,
				"retro" : retro
      };
      const response = await send_instore(param);
      if(response != null){  
          // TO DO: unblockUI();
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            }); 
            window.location.reload();
            this.isprocessing[index] = "0";
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
            this.isprocessing[index] = "0";
          }
        }
      this.loading = false;
      return; 
    }
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("estoreTab") || "0");
      setCurrentPageTitle("Estore");

    });
    
  }
});
