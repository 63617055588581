
import { defineComponent } from "vue";
import {viewExpiryDate}  from "@/api/estore";
import Swal from "sweetalert2/dist/sweetalert2.min.js";


export default defineComponent({
  name: "estore_expiry", 
  data(){
      return { 
        prodcode: "" as String,
        prodname: "" as String,
        expiry_list: [] as any
      }
  },
  methods: {
    async view_expiry_date(pcode, pname){
      
      
      
    }
  },
  mounted(){
      //this.view_expiry_date(this.pcode);
  },
  components: {},
  props: {
    widgetClasses: String,
    pname: String,
    list2: Object
  }
});
